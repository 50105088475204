import showToastify from '../utils/toastify';
import "toastify-js/src/toastify.css"
import axios from 'axios'

export default function contatoJs() {

    const contatoFormSubmit = document.querySelector('#enter-in-contact')
    const btnSubmit = document.querySelector("#enter-in-contact button[type=submit]")
   

    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function(event) {
         event.preventDefault();

        disabledBtn()

        const form_data = {
            nome: document.querySelector('#enter-in-contact input[name=nome]').value,
            email: document.querySelector('#enter-in-contact input[name=email]').value,
            telefone: document.querySelector('#enter-in-contact input[name=telefone]').value,
            mensagem: document.querySelector('#enter-in-contact textarea[name=mensagem]').value

        }
       
        const url = "/fale-conosco/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));



        function showMessage(response) {
            const message = response.data ? response.data.message : response.message
            showToastify(message)
            contatoFormSubmit.reset()
            setTimeout(enabledBtn, 1000)
        }
    
        
    
        function disabledBtn() {
            btnSubmit.disabled = true
            btnSubmit.classList.add("disabled")
        }
    
        function enabledBtn() {
            btnSubmit.disabled = false
            btnSubmit.classList.remove("disabled")
        }
    });
}