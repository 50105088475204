import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import contatoJs from './modules/formularioContato';
import Swiper from 'swiper';
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome()
PageLogin()?.init()

// utils logs
logs()
contatoJs()

const itemsMenu = document.querySelectorAll('#mobile-menu-items-wrapper a')
if(itemsMenu){
    itemsMenu.forEach(item  => {
        item.addEventListener('click', () =>{
            const button = document.querySelector('button[data-xclose="modalMenu"]')
            button.click()
        })
      

    })
}


const depoimentos = new Swiper('.swiperDepoimentos',
{
    slidesPerView: 1.1,
    spaceBetween: 16,
    autoHeight:true,

    breakpoints:{
        1024:{
            slidesPerView: 'auto', 
            autoHeight:false,
        }
    },
    autoplay:{
        delay:5000,
        disableOnInteraction: true
    },
    scrollbar: {
        el: '#depoimentos .swiper-scrollbar',
        draggable: true,
      },

})